import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'moment/locale/ru'; // Импорт русской локализации
import {
  ActionSheet,
  Dialog,
  DotLoading,
  Empty,
  Skeleton,
  Toast,
} from 'antd-mobile';
import { Typography, Collapse, ConfigProvider } from 'antd';
import { Action } from 'antd-mobile/es/components/action-sheet';
import moment from 'moment';
import { UpOutline, DownOutline } from 'antd-mobile-icons';

import {
  EOrderStatus,
  EOrderStatus_Input,
  OrderWithTickets,
} from '../../__generated__/types';
import { showError } from '../../utils';
import { NavBar } from '../../components/nav-bar';

import { useGetRawFlight } from './gql/__generated__/GetRawFlight';
import { useGetPassengersByStations } from './gql/__generated__/GetPassengersByStations';
import { useChangeStatus } from './gql/__generated__/ChangeStatus';
import { AggregatedPassenger, aggregatePassengers } from './utils';
import { PassengersList } from './list';

moment.locale('ru'); // Установка локализации

const PassengersPage = () => {
  const navigate = useNavigate();
  const { flightId } = useParams();

  const [visible, setVisible] = useState(false);
  const [selectedPassenger, setSelectedPassenger] =
    useState<AggregatedPassenger>();

  const { data: flightData, loading: flightLoading } = useGetRawFlight({
    skip: !flightId,
    variables: {
      req: {
        id: flightId!,
      },
    },
    fetchPolicy: 'network-only',
    onError: showError,
  });

  const flight = flightData?.flights_getRaw?.flight;

  const { data: passengersData, loading: passengersLoading } =
    useGetPassengersByStations({
      skip: !flightId,
      variables: {
        req: {
          flightId: flightId!,
          statuses: [
            EOrderStatus_Input.Active,
            EOrderStatus_Input.Confirmed,
            EOrderStatus_Input.NotCome,
          ],
        },
      },
      fetchPolicy: 'network-only',
      onError: showError,
    });

  const [changeStatus] = useChangeStatus({ onError: showError });

  const stations = passengersData?.tickets_getPassengersByStations?.items || [];

  const handleBack = () => {
    navigate('../');
  };

  const handleChangeStatus = async (
    ticketId: string,
    status: EOrderStatus_Input,
  ) => {
    try {
      const res = await changeStatus({
        variables: {
          req: {
            ticketId,
            status,
          },
        },
      });
      if (res.errors) {
        throw Error();
      }
      Toast.show({
        icon: 'success',
        content: 'Статус обновлен',
      });
      setVisible(false);
    } catch (e) {
      Toast.show({
        icon: 'fail',
        content: 'Ошибка обновления',
      });
    }
  };

  const getActions = (aggregatedPassenger?: AggregatedPassenger): Action[] => {
    if (!aggregatedPassenger) {
      return [];
    }

    const { passenger, ticket } = aggregatedPassenger;

    return [
      {
        text: (
          <a href={`tel:${passenger.phoneNumber}`} className="tel-link">
            Позвонить
          </a>
        ),
        description: passenger.phoneNumber,
        key: 'call',
      },
      {
        text: 'Пришел',
        key: 'come',
        disabled: ticket.status !== EOrderStatus.Active,
        onClick: () => {
          handleChangeStatus(ticket.id, EOrderStatus_Input.Confirmed);
        },
      },
      {
        text: 'Не Пришел',
        key: 'not-come',
        disabled: ticket.status !== EOrderStatus.Active,
        danger: true,
        onClick: () => {
          Dialog.confirm({
            content: 'Действительно не пришел?',
            confirmText: 'Да',
            cancelText: 'Закрыть',
            onConfirm: async () => {
              handleChangeStatus(ticket.id, EOrderStatus_Input.NotCome);
            },
          });
        },
      },
    ];
  };

  const handlePassengerClick = (aggregatedPassenger: AggregatedPassenger) => {
    setSelectedPassenger(aggregatedPassenger);
    setVisible(true);
  };

  return (
    <div className="page-layout">
      <NavBar onBack={handleBack}>
        {flightLoading ? (
          <DotLoading />
        ) : (
          <>
            <div>{flight?.name}</div>
            <div>
              <Typography.Text type="secondary">
                {moment(flight?.departureTime).format('LLLL')}
              </Typography.Text>
            </div>
          </>
        )}
      </NavBar>

      <div className="page-layout__body">
        {passengersLoading ? (
          <>
            <Skeleton.Title />
            <Skeleton.Paragraph />
            <Skeleton.Paragraph />
          </>
        ) : !stations?.length ? (
          <Empty description="Нет пассажиров" />
        ) : (
          <>
            <ConfigProvider
              theme={{
                components: {
                  Collapse: {
                    contentPadding: '0',
                    headerBg: 'transparent',
                    headerPadding: '4px 12px',
                  },
                },
              }}
            >
              <Collapse
                expandIconPosition="end"
                bordered={false}
                style={{ alignItems: 'center' }}
                defaultActiveKey={stations.map((_, i: number) => i)}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <div>
                      <UpOutline color="var(--adm-color-light)" fontSize={19} />
                    </div>
                  ) : (
                    <div>
                      <DownOutline
                        color="var(--adm-color-light)"
                        fontSize={19}
                      />
                    </div>
                  )
                }
                items={stations.map((stationData, index: number) => {
                  const {
                    station,
                    city,
                    getOff = [],
                    seat = [],
                  } = stationData || {};

                  const aggregatedSeatPassengers: AggregatedPassenger[] =
                    aggregatePassengers(seat as OrderWithTickets[]);
                  const aggregatedGetOffPassengers: AggregatedPassenger[] =
                    aggregatePassengers(getOff as OrderWithTickets[]);

                  return {
                    key: index,
                    styles: { header: { alignItems: 'center' } },
                    label: (
                      <h3
                        style={{
                          color: 'var(--adm-color-text-secondary)',
                        }}
                      >
                        {city} ({station})
                      </h3>
                    ),
                    children: (
                      <>
                        {Boolean(aggregatedSeatPassengers.length) && (
                          <PassengersList
                            header={`На посадку (${aggregatedSeatPassengers.length})`}
                            aggregatedPassengers={aggregatedSeatPassengers}
                            onClick={handlePassengerClick}
                          />
                        )}

                        {Boolean(aggregatedGetOffPassengers.length) && (
                          <PassengersList
                            header={`На высадку (${aggregatedGetOffPassengers.length})`}
                            aggregatedPassengers={aggregatedGetOffPassengers}
                            onClick={handlePassengerClick}
                          />
                        )}
                      </>
                    ),
                  };
                })}
              />
            </ConfigProvider>
          </>
        )}
      </div>

      <ActionSheet
        visible={visible}
        actions={getActions(selectedPassenger)}
        onClose={() => setVisible(false)}
      />
    </div>
  );
};

export default PassengersPage;
