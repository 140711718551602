// 🛑 NOTICE: __generated__ folders should be added to .gitignore
import * as Types from '../../../../__generated__/types';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetRawFlightVariables = Types.Exact<{
  req?: Types.InputMaybe<Types.GetRawFlightRequest_Input>;
}>;


export type GetRawFlight = { __typename: 'Query', flights_getRaw?: { __typename: 'GetRawFlightResponse', flight: { __typename: 'RawFlight', id: string, name: string, departureTime: string } } | null };


export const GetRawFlightDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetRawFlight"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"req"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"GetRawFlightRequest_Input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flights_getRaw"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"req"},"value":{"kind":"Variable","name":{"kind":"Name","value":"req"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"flight"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"departureTime"}}]}}]}}]}}]} as unknown as DocumentNode;

/**
 * __useGetRawFlight__
 *
 * To run a query within a React component, call `useGetRawFlight` and pass it any options that fit your needs.
 * When your component renders, `useGetRawFlight` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRawFlight({
 *   variables: {
 *      req: // value for 'req'
 *   },
 * });
 */
export function useGetRawFlight(baseOptions?: Apollo.QueryHookOptions<GetRawFlight, GetRawFlightVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRawFlight, GetRawFlightVariables>(GetRawFlightDocument, options);
      }
export function useGetRawFlightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRawFlight, GetRawFlightVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRawFlight, GetRawFlightVariables>(GetRawFlightDocument, options);
        }
export function useGetRawFlightSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetRawFlight, GetRawFlightVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetRawFlight, GetRawFlightVariables>(GetRawFlightDocument, options);
        }
export type GetRawFlightHookResult = ReturnType<typeof useGetRawFlight>;
export type GetRawFlightLazyQueryHookResult = ReturnType<typeof useGetRawFlightLazyQuery>;
export type GetRawFlightSuspenseQueryHookResult = ReturnType<typeof useGetRawFlightSuspenseQuery>;
export type GetRawFlightQueryResult = Apollo.QueryResult<GetRawFlight, GetRawFlightVariables>;